































































































import {Vue,Component,Prop} from 'vue-property-decorator'
import {State,Action} from 'vuex-class';
import { Planta } from '@/entidades/Maestro/Planta';
import {ProduccionListado,EliminarPallet} from '@/entidades/KTB/Produccion/ProduccionQuery';
import Loading from '@/entidades/Sistema/Loading';
import Alerta from '@/entidades/Sistema/Alerta';
@Component({
    name : 'ProduccionView',
})
export default class RecepcionTerceroDetalle extends Vue
{
    @Prop({type:Array,required:true}) item!:Array<ProduccionListado>;
    @State('usuarioId',{namespace:'authModule'}) usuarioId!:string;
    @State('plantaAsignada',{namespace: 'authModule'}) plantas!:Array<Planta>;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    modalEliminar:boolean=false;
    motivoEliminacion:string="";
    modal:boolean=false;
    componenteName:string="";
    listadoProduccion:Array<ProduccionListado>=[];
    itemProduccion:ProduccionListado| undefined = undefined;
    modalConfirmacion:boolean=false;
    messageConfirmacion:string="";
    componentDialog="";
    tab:number=0;

    header:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"# Pallet",sortable:false,value:'palletId'},
            {text:"Hacienda",sortable:false,value:'haciendaName'},
            {text:"Fecha",sortable:false,value:'fecha'},
            {text:"Turno",sortable:false,value:'turnoName'},
            {text:"Largo",sortable:false,value:'largoName'},
            {text:"Espesor",sortable:true,value:'espesorName'},
            {text:"# Plantillas",sortable:false,value:'numPlantilla'},
            {text:"Volumen",sortable:true,value:'volumen'},
            {text:"Mesa",sortable:true,value:'mesaName'},
            {text:"Linea",sortable:false,value:'lineaName'},
            {text:"Trabajador",sortable:false,value:'empleadoName'},
            {text:"Estado",sortable:false,value:'estadoDescripcion'},
            {text:"Eliminar",sortable:false,value:'eliminar'}
    ];
    
    headerEspesor:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"espesor",sortable:true,value:'espesor'},
            {text:"volumen",sortable:true,value:'volumen'}
    ];

      headerLargo:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"Largo",sortable:true,value:'largo'},
            {text:"volumen",sortable:true,value:'volumen'}
    ];

    limpiar()
    {
        this.modal = false;
        this.componenteName = "";

    }

    eliminarPalletModal(item:ProduccionListado)
    {
        //
        this.itemProduccion = item;
        this.messageConfirmacion = `Estas Seguro de Eliminar el pallet ${item.palletId}`;
        this.modalEliminar = true;
    }
    eliminarPallet()
    {

        // this.componentDialog = "ConfirmacionDialog";
        // this.modalConfirmacion = true;

        // this.propsEnviar ={'open' : this.modalConfirmacion,'message' :this.messageConfirmacion,
        //     'methodDialog':this.confirmacionDialogResponse,'objeto':item,
        //     'tipo': 2};
    }

     async confirmacionDialogResponse()
    {

        try
        {
            if(this.itemProduccion != undefined)
            {
                this.changeLoading(new Loading(true,"Eliminando Pallet"));
                this.itemProduccion.estadoDescripcion = this.motivoEliminacion;
                this.itemProduccion.usuarioId = this.usuarioId;
                var response = await EliminarPallet(this.itemProduccion);
                if(response.isSuccess)
                {
                    this.changeAlerta(new Alerta(response.isMessage,true,"primary",3000));
                    this.modalEliminar = false;
                     this.$emit('limpiar');
                    return;
                }
                this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
            }
        }
        catch(error)
        {

        }
        finally
        {
                this.changeLoading(new Loading(false,""));
        }

    }


    async mounted()
    {
       
    }
}
